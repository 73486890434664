.topMainHdr{
	position: relative;
	width: 100%;
	padding:22px 0;
	z-index: 9999;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	background: #006eda;
	@media only screen and (max-width:991px){
		padding: 10px 0;
	}
}
.hdrRow{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;	
}
.hdrLogo{
	position: relative;
	a{
	position: absolute;
	left: 0;
	top: -22px;
	}
}
.hdrLogo img{
	max-width: 120px;	
	@media only screen and (max-width:991px){
		max-width: 80px;
		margin-top: 5px;
	}
	@media only screen and (max-width:575px){
		max-width: 80px;
		margin-top: 5px;
	}
}





/*====== Menu Style Start ======*/
.hdrMenuPrt{
	z-index: 99;
}
.headerMenu{
	font-size: 18px;
	color: var(--primary);
	font-weight: 400;
	letter-spacing: 0.5px;
	@media only screen and (max-width: 991px){
		background-color: transparent;
		z-index: 99;
		font-size: 15px;
	}
}


.headerMenu li{ margin: 0 5px; position: relative; }
.headerMenu li:first-child{ margin-left: 0; }
.headerMenu li:last-child{ margin-right: 0; }
.headerMenu li a{ position: relative; color:#fff; padding: 6px 5px; text-decoration: none; font-size: 14px;}
.headerMenu li a:hover, .headerMenu .headerMenu li.active a{ color:#fff; text-decoration: underline;}

/*Hamburger Style*/
.hdrMenuPrt button{ 
	width: 27px; 
	height: 20px; 
	position: relative; 
	margin: 5px 0 0; 
	cursor: pointer; 
	outline: 0 !important; 
	border: none !important;
-webkit-transform: rotate(0deg);
-moz-transform: rotate(0deg);
-o-transform: rotate(0deg);
transform: rotate(0deg);
-webkit-transition: .5s ease-in-out;
-moz-transition: .5s ease-in-out;
-o-transition: .5s ease-in-out;
transition: .5s ease-in-out; 
z-index: 9999;
}
.hdrMenuPrt button span{ 
	position: absolute;
	right: 0;
	width: 22px;
	height: 2px;
	background-color: #0c2d86;
	border-radius: 30px;
	-webkit-transition: .2s ease-in-out;
	-moz-transition: .2s ease-in-out;
	-o-transition: .2s ease-in-out;
	transition: .2s ease-in-out; 
}
.hdrMenuPrt button span{ background: var(--white); }
.hdrMenuPrt button span:nth-child(1){ top: 0px; }
.hdrMenuPrt button span:nth-child(2), .hdrMenuPrt button span:nth-child(3){ top: 8px; width: 100%; }
.hdrMenuPrt button span:nth-child(4){ top: 16px; }
.user_profile_right {	
	background: #ffffff;	
	border-radius: 8px;
	padding: 10px 20px;
	font-size: 15px;
	font-weight: 600;
	line-height: 1.2;
	cursor: pointer;
	color: #004f9c;
	position: relative;
	img {
		width: 25px;
		margin-right: 5px;
		fill: #006eda;
	}
	&:hover .user_dropdown {
		display: block;
	}
}
.user_box {	
	display: flex;
	align-items: center;	
	.userDownArrow {
		position: relative;
		top: 2px;
	}
	.user_dropdown {
		display: none;
		position: absolute;
		right: 0;
		top: 100%;
		background: #ffffff;
		box-shadow: 0 0 5px rgba(0,0,0,.1);
		border-radius: 5px;
		width: 200px;
		ul {
			padding: 5px 0;
		}
		ul li{
			border-bottom: 1px solid #ebeef1;
			&:last-child {
				border-bottom: none;
			}
			svg {
				width: 25px;
				margin-right: 5px;
				fill: #8b9cb3;
			}
			a {
				font-size: 15px;
				font-weight: 500;
				display: block;
				padding: 10px 15px;
				color: #6a7c94;
				&:hover {
					background: #f3f3f3;
					color: #004f9c;
					svg {
						fill: #004f9c;
					}
				}
			}
		}
	}
}
/*====== Menu Style End ======*/


/*=========== Menu Responsive Style Start ===========*/
@media only screen and (min-width: 992px) {
.topMainHdr .hdrMenuPrt button{
	display: none;
}

.headerMenu li a::before{
	left: 0;
}
.headerMenu li a::after{
	right: 0;
}

}


@media only screen and (max-width: 991px) {
	.headerMenu{
		background-color: transparent;
		z-index: 99;
		font-size: 15px;
	}
	
}
/*=========== Menu Responsive Style End ===========*/














