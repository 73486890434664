/*================ Banner Style Part ================*/
.successMainSec{
	position: relative;
	background-color:#eff1f7;
	padding: 40px 0 30px 0;
	width: 100%;
	height: 100vh;
	display: flex;	
	align-items: center;
	justify-content: center;
	@media only screen and (max-width:767px){
		padding: 20px 0 30px 0;
	}
	.successMainPopup{
		width: 35%;
		background: #fff;
		padding: 50px;
		border-radius: 10px;
		box-shadow: 0 5px 5px rgba($color: #000000, $alpha: 0.1);
		text-align: center;
		@media only screen and (max-width:900px){
			width: 70%;
		}
		@media only screen and (max-width:560px){
			width: 90%;
		}
		@media only screen and (max-width:480px){
			width: 93%;
		}
		img{
			width: 15%;
			margin-bottom: 30px;
			fill: green;
			path {
				fill: #008000;
			  }
		}
		h2{
			font-size: 30px;
			font-weight: bold;
			color: #000;
		}
		p{
			line-height: 1.3;
		}
		.okBtn{
			background: 2;
		}
	}
}


