@import "./variables.css";

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body,
html {
  width: 100%;
  padding: 0;
  margin: 0;
}
body {
  font-family: "Montserrat", sans-serif;
  color: var(--primary);
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
  background-color: var(--white);
}
div,
table,
td,
tr,
section,
h1,
h2,
h3,
h4,
h5,
h6,
p,
section,
article,
figure {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
}
a,
input,
textarea,
button {
  outline: 0;
  transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
input:focus,
input:hover,
textarea:focus,
textarea:hover {
  box-shadow: none !important;
  outline: none;
}
select {
  outline: 0;
}
input::placeholder {
  opacity: 1;
}
a {
  text-decoration: none;
  border: none;
  outline: 0;
  color: var(--primary);
}
a:hover {
  text-decoration: none;
  border: none;
  outline: 0;
  color: var(--secondary);
}
a:focus {
  text-decoration: none;
  border: none;
  outline: 0;
}
button,
button:focus {
  text-decoration: none;
  border: none;
  outline: 0;
}
ul,
ol,
ul li,
ol li {
  list-style: none;
  margin: 0;
  padding: 0;
}
p:empty {
  display: none;
}
img {
  width: auto;
  max-width: 100%;
  height: auto;
}
body::selection {
  background: #00589e;
  color: #ffffff;
}
body::-moz-selection {
  background: #00589e;
  color: #ffffff;
}
p {
  font-size: 17px;
  color: var(--textColor);
  line-height: 32px;
  margin-bottom: 20px;
}
h1 {
  font-size: 42px;
  font-weight: 700;
  line-height: 1.2;
  color: var(--text-heading-3);
  margin-bottom: 20px;
}
h2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  color: var(--text-heading-2);
  text-transform: uppercase;
  margin-bottom: 20px;
}
h3 {
  font-size: 34px;
  font-weight: 700;
  line-height: 1.2;
  color: var(--text-heading-3);
  margin-bottom: 20px;
}
h3 span {
  display: block;
}

/*================ Global CSS Part ================*/
body.body-fixed {
  /* position: fixed; */
  width: 100%;
  overflow: hidden;
}
/* body.body-fixed::before{
	position: absolute;
	content: '';
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.4);
} */
.secSmallHdn {
  font-size: 16px;
  color: #006eda;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.secLargeHdn {
  font-size: 56px;
  color: var(--primary);
  font-weight: 600;
  margin-bottom: 15px;
}

a.dfltBtn,
button.dfltBtn {
  position: relative;
  font-size: 15px;
  color: var(--white);
  text-align: center;
  letter-spacing: 1px;
  display: inline-block;
  background-color: var(--secondary);
  border-radius: 12px;
  padding: 14px 40px 16px 40px;
  overflow: hidden;
}
a.dfltBtn span,
button.dfltBtn span {
  position: relative;
  display: inline-block;
  z-index: 3;
}
a.dfltBtn::before,
a.dfltBtn::after,
button.dfltBtn::before,
button.dfltBtn::after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  background-color: var(--primary);
  top: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
a.dfltBtn::before,
button.dfltBtn::before {
  left: 0;
}
a.dfltBtn::after,
button.dfltBtn::after {
  right: 0;
}
a.dfltBtn:hover::before,
button.dfltBtn:hover::before {
  width: 50%;
}
a.dfltBtn:hover::after,
button.dfltBtn:hover::after {
  width: 50%;
}
a.dfltBtn:hover,
button.dfltBtn:hover {
  color: var(--white);
}

/*================ Sticky Header Style Part ================*/
.siteTopNavbar.stickyHeader {
  position: fixed;
  top: 0;
  left: 0;
  padding: 15px 0;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.15);
}
.siteTopNavbar.stickyHeader img {
  max-width: 80px;
}

/*================ Menu Style Part ================*/
.siteTopNavbar .navbar {
  padding: 0;
  width: 100%;
  justify-content: center;
}
.siteTopNavbar button.open span:nth-child(1) {
  top: 11px;
  width: 0%;
  left: 50%;
}
.siteTopNavbar button.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.siteTopNavbar button.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.siteTopNavbar button.open span:nth-child(4) {
  top: 11px;
  width: 0%;
  left: 50%;
}

@media only screen and (max-width: 1199px) {
  h1 {
    font-size: 45px;
  }
  h3 {
    font-size: 38px;
  }
}

@media only screen and (max-width: 991px) {
  h1 {
    font-size: 34px;
  }
  h3 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 28px;
    text-align: center;
  }
  h3 {
    font-size: 24px;
    text-align: center;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  /* a.dfltBtn,
	button.dfltBtn{
		padding: 10px 25px 12px 25px;
	} */
}

@media only screen and (max-width: 991px) {
  .siteTopNavbar nav.navbar {
    width: 0;
    height: 100vh;
    background-color: #31302e;
    padding: 0 0 100px 0;
    position: absolute;
    top: 0;
    left: -50px;
    display: inline-block;
    box-shadow: 0 7px 13px rgba(0, 0, 0, 0.4);
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }
  .siteTopNavbar.show-nav nav.navbar {
    width: 350px;
    left: 0;
  }
  .siteTopNavbar.show-nav nav.navbar .navbar-collapse {
    height: 100%;
  }
  nav.navbar ul {
    height: 100%;
    overflow-y: scroll;
    padding: 10px 0 10px 0;
  }
  .siteTopNavbar li:nth-child(6) {
    margin-left: 0 !important;
  }

  .siteTopNavbar {
    float: none;
    justify-content: flex-start;
  }
  .siteTopNavbar li,
  .dropdown-menu li {
    text-align: left;
    border-bottom: 1px solid #6f6f6f;
    margin: 0;
  }
  .siteTopNavbar li:last-child,
  .dropdown-menu li:last-child {
    border-bottom: none;
  }
  .siteTopNavbar li a {
    border: none;
    display: inline-block;
    width: 100%;
    padding: 13px 20px;
    color: var(--white);
  }
  .siteTopNavbar li a:hover,
  .siteTopNavbar li.active a {
    color: var(--secondary);
  }
  .dropdown-toggle {
    padding-right: 40px !important;
  }
  .dropdown-menu {
    padding-top: 0;
    margin-left: 0;
    padding-top: 0;
  }
  .dropdown_menu_style::before {
    display: none;
  }
  .dropdown_menu_style {
    box-shadow: none;
  }
  .dropdown-menu li {
    background-color: transparent;
    font-size: 14px !important;
  }
  .dropdown-menu li a {
    padding: 6px 10px 6px 35px !important;
    color: #ffffff;
  }
  .hdrMenuPrt button {
    padding: 13px;
  }
  .dropdown-toggle::after {
    top: 18px;
    right: 15px;
  }
}

.radius50 {
  border-radius: 50px !important;
}

.loading-text.svelte-1xm7zx5 {
  display: none;
}
.arr-flex-center.svelte-1k594gt {
  display: none;
}




.siteTopNavbar.stickyHeader .open>.dropdown-menu{
  margin-top: 15px;
}

.open>.dropdown-menu {
  display: block;
  width: 200px;
  /* right: 0; */
  left:-50%;
  margin-top: 22px;
  border-radius: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border: solid 1px #ccc;
  padding: 0;
}
.dropdown-menu li{
  border-bottom: solid 1px #ccc;
  margin: 0;
}
.dropdown-menu li a{
	color: #000 !important;
  padding: 10px 10px !important;
  display: block; 
  font-size: 15px;
}
.dropdown-menu li a:hover{
  text-decoration: none;
  color: #006eda !important;
}
.dropdown-menu li:last-child{
  border-bottom: none;
}

@media only screen and (max-width: 991px) {
  .open>.dropdown-menu {
    margin-top: 0;
    background: none;
    border: none;
    box-shadow: none;
    width: 100%;
  }
  .dropdown-menu li{
    border-bottom: none;
    margin: 0;
  }
  .dropdown-menu li a{
    color: #fff !important;
    padding: 13px 20px !important;
    border-top: 1px solid #6f6f6f;
  }
}