.footer_section{
	position: relative;
	padding: 70px 0;
	background: var(--lightGrayBg);
	@media only screen and (max-width:575px){
		padding: 20px 0;
	}
	.footer_logo {
		margin-bottom: 20px;
		@media only screen and (max-width:767px){
			text-align: center;
		}
		p{
			padding-right: 50px;
			@media only screen and (max-width:767px){
				padding-right: 0;
			}
		}
	}
	h4 {
		font-size: 17px;
		line-height: 1.2;
		font-weight: 500;
		color: var(--primary);
		margin-bottom: 20px;
	}
	p {
		font-size: 14px;
		line-height: 22px;
		color: var(--footerTextColor);		
		margin: 20px 0 0 0;
	}
	ul {
		padding: 0;
		margin: 0;
		@media only screen and (max-width:575px){
			margin-bottom: 15px;
		}
		li a{
			font-size: 14px;
			line-height: 36px;
			color: var(--footerTextColor);
			@media only screen and (max-width:575px){
				line-height: 28px;
			}
			&:hover {
				color: var(--secondary);
			}
		}
	}
	.footerContsec{
		width: 20%;
		@media only screen and (max-width:667px){
			width: 50%;			
			p{
				text-align: left;
			}
			
		}
		@media only screen and (max-width:500px){
			width: 100%;
			text-align: left;
			margin-bottom: 20px;
		}
	}
}



.email_subscribe {
	padding-top: 15px;
	.dfltBtn{
		border-radius:50px !important;
	}
	input {
		width: 100%;
		height: 45px;
		background-color: #f1f2f6;
		border-radius: 30px;
		border: none;
		outline: none;
		padding: 5px 20px;
		font-family: var(--footerFont);
		font-size: 12px;
		color: var(--footerTextColor);
		font-weight: 500;
		line-height: 18px;
	}
	button {
		width: 100%;
		margin-top: 10px;
		font-size: 14px;
		text-transform: capitalize;
		letter-spacing: 1px;
		border-radius: 30px;	
	}
} 

.email_subscribe input::placeholder { color: var(--footerTextColor); }
.email_subscribe input::-webkit-input-placeholder { color: var(--footerTextColor); }
.email_subscribe input::-moz-placeholder { color: var(--footerTextColor); }
.email_subscribe input:-moz-placeholder { color: var(--footerTextColor); }
.email_subscribe input:-ms-input-placeholder { color: var(--footerTextColor); }



.ftrCopyrightRow{
	position: relative;
	border-top: 1px solid #dbdbdb;
	padding-top: 50px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	margin-top: 60px;
	@media only screen and (max-width:575px){
		padding-top: 30px;
	}
}
.copyrightLeftText{
	@media only screen and (max-width:991px){
		width: 100%;
		text-align: center;
	}
}
.copyrightLeftText p{
	font-family: var(--footerFont);
	font-size: 12px;
	color: var(--footerTextColor);
	font-weight: 500;
	line-height: 22px;
	margin: 0;
	a {
		color: var(--footerTextColor);
		&:hover {
			color: var(--secondary);
		}
	}
}


.copyrightRightSocialIcon{
	ul{
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		li{
			width: 35px;
			height: 35px;
			border-radius: 100%;
			background: var(--background-bg);
			margin-left: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.2);
			a{
				color: #000;
				font-size: 17px;
				&:hover{	
					color: #fff;
				}				
			}
			&:hover{
				background: var(--secondary);	
				color: #fff;
			}
		}
	}
	@media only screen and (max-width:991px){
		width: 100%;
		text-align: center;
		margin-top: 20px;
	}
}