/*================ Banner Style Part ================*/
.banner_section {
	position: relative;
	background-color: var(--lightGrayBg);
	padding: 50px 0 0 0;

	.banner_content {
		padding-top: 60px;

		p {
			padding-right: 50px;
		}
	}

	.banner_action {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding-bottom: 60px;

		.qr_code {
			margin-left: 15px;
			width: 130px;
		}

		a {
			margin-right: 20px;
		}

		.login_btn {
			position: relative;
			font-size: 17px;
			font-weight: 500;
			color: var(--secondary);
			text-align: center;
			display: inline-block;
			background: var(--white);
			border: 2px solid var(--secondary);
			border-radius: 12px;
			padding: 11px 40px 12px 40px;
			overflow: hidden;

			&:hover {
				background: var(--primary);
				border-color: var(--primary);
				color: var(--white);
			}
		}
	}

}

.banner_img {
	display: flex;
	justify-content: flex-end;
	height: 100%;
	align-items: flex-end;	
}

.about_content {
	padding: 80px 0;

	p {
		padding-right: 50px;
	}
}

.genius_section {
	background: var(--background-bg);
	padding: 60px 0;
}

.simulattor_section {
	background: var(--background-bg);
	position: relative;
	padding: 160px 0;
}

.simulattor_img_section {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	width: 50%;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.offer_content {
	padding-top: 60px;
}

.download_section {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: right top;
	background-image: url(../../../assets/images/download_bg.png);
	padding: 135px 0;

	p {
		padding: 0 150px 20px 0;
	}

	.app_link_action {
		display: flex;
		align-items: center;

		a {
			margin-right: 10px;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.blog_section {
	background-color: var(--lightGrayBg);
	padding: 50px 0;

	h3 {
		text-align: center;
		margin-bottom: 40px;
	}

	.item {
		padding-bottom: 20px;
	}

	.blog_box {
		transition: all 0.2s ease-in-out;
	}

	.blog_box:hover {
		box-shadow: 0 0 20px rgba(0, 0, 0, .15);
	}

	.blog_img {
		height: 300px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.blog_content {
		padding: 20px 15px;

		h4 {
			font-size: 22px;
			line-height: 1.2;
			font-weight: 600;
			color: var(--text-heading-3);
			margin: 0 0 15px;
		}

		p {
			font-size: 16px;
			line-height: 1.6;
		}
	}
}

@media only screen and (max-width:1199px) {
	.download_section {
		background-position: center top;
	}

	.qr_code {
		display: none;
	}
}

@media only screen and (max-width:991px) {
	.simulattor_section {
		padding: 80px 0;
	}

	.download_section {
		padding: 80px 0;
	}

	.banner_section {
		.banner_content {
			padding-top: 65px;

			h1 {
				text-align: center;
			}

			p {
				text-align: center;
				padding-right: 0;
			}
		}

	}
}

@media only screen and (max-width:767px) {
	.banner_section {
		padding-top: 0;
		padding-bottom: 0;

		.banner_action {
			margin-bottom: 40px;
			text-align: center;
		}
	}

	.simulattor_section {
		padding-top: 0;
		padding-bottom: 60px;
	}

	.simulattor_img_section {
		position: relative;
		width: 100%;
		margin-bottom: 30px;

		img {
			width: 100%;
		}
	}

	.app_link_action {
		img {
			width: 130px;
		}
	}

	.action_link {
		width: 100%;
	}

	.about_content {
		text-align: center;

		h2 {
			padding-top: 30px;
		}

		p {
			padding-right: 0;
		}
	}

	.simulattor_section {
		text-align: center;

		h2 {
			padding-top: 30px;
		}

		p {
			padding-right: 0;
		}
	}

	.offer_content {
		text-align: center;

		h2 {
			padding-top: 30px;
		}

		p {
			padding-right: 0;
		}
	}

	.offer_content {
		padding-bottom: 60px;

		.mobImg {
			margin-top: 40px !important;
		}
	}

	.download_section {
		text-align: center;

		p {
			padding-right: 0;
		}

		.app_link_action {
			justify-content: center;
		}
	}

}