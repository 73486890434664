.chatBoxWrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--white);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
}
.chatBoxHeader {
  flex: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: 1px solid #ececec;
  .chatBoxHeaderLeft {
    display: flex;
    align-items: center;
    img {
      width: 50px;
      margin-right: 15px;
    }
    h4 {
      font-size: 20px;
      font-weight: 600;
      margin: 0;
    }
  }
  .chatBoxHeaderRight {
    font-size: 16px;
    line-height: 1.2;
    font-weight: 500;
    color: #7e7e7e;
    b {
      font-size: 17px;
      font-weight: 600;
      color: #212121;
    }
    span {
      font-size: 22px;
      font-weight: 600;
      color: #31c522;
    }
  }
}
.chatInfoWrapper {
  flex: 1;
  padding: 25px 15px 1px;
  overflow: auto;
}
.chatQuestionWrapper {
  margin-bottom: 20px;
  display: flex;
  &.chatRight {
    justify-content: flex-end;
    .chatQuestionCol {
      background: #ececec;
      border-radius: 15px;
      border-bottom-right-radius: 0;
    }
    .chatTime {
      text-align: right;
      color: #006eda;
    }
    .chatQuestionBox {
      padding-left: 0;
      padding-right: 50px;
    }
  }
  .chatQuestionBox {
    position: relative;
    max-width: 620px;
    padding-left: 50px;
  }
  .chatQuestionCol {
    background: #dceeff;
    border-radius: 15px;
    border-bottom-left-radius: 0;
    padding: 12px 15px;
    font-size: 14px;
    line-height: 1.6;
    color: #000000;
    min-height: 46px;
    min-width: 100px;
    b {
      display: block;
      margin-bottom: 5px;
    }
  }
  .chatTime {
    display: block;
    margin-top: 8px;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.2;
    color: #000000;
  }
  .chatQuestionImg {
    position: absolute;
    left: 0;
    top: 5px;
    img {
      max-width: 40px;
    }
  }
}

.chatBoxBottm {
  flex: 0 auto;
  border-top: 1px solid #ececec;
  padding: 10px 0;
  .chatForm {
    position: relative;
    padding: 0 15px;
  }
  input {
    background: #fff;
    border-radius: 35px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 50px;
    border: none;
    padding: 0 20px 5px;
    font-size: 15px;
    &:focus,
    &:hover {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.15) !important;
    }
  }
  .chatSendAction {
    position: absolute;
    right: 20px;
    top: 5px;
    button {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background: #006eda;
      border: none;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
      }
    }
  }
}

.noChatMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.5rem;
  color: #999;
}

.floatingVerifyBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 10px;
  span{
    font-size: 14px;
    font-weight: 500;
  }
}

.verifyButton {
  background-color: #89c03e;
  color: #fff;
  border: none;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.verifyButton:hover {
  background-color: #45a049;
}

/*Loader css */
.loaderDots {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: relative;
  animation: loader 1s linear alternate infinite;
  top: -30px;
  left: 45px;
}

@keyframes loader {
  0% {
    box-shadow: -30px 40px 0 2px #0052ec,
    -15px 40px 0 0 rgba(0, 82, 236, 0.2),
    0 40px 0 0 rgba(0, 82, 236, 0.2),
  }
  25% {
    box-shadow: -30px 40px 0 0 rgba(0, 82, 236, 0.2),
    -15px 40px 0 2px #0052ec,
    0 40px 0 0 rgba(0, 82, 236, 0.2),
  }
  50% {
    box-shadow: -30px 40px 0 0 rgba(0, 82, 236, 0.2),
    -15px 40px 0 0 rgba(0, 82, 236, 0.2),
    0 40px 0 2px #0052ec,
  }
  75% {
    box-shadow: -30px 40px 0 0 rgba(0, 82, 236, 0.2),
    -15px 40px 0 0 rgba(0, 82, 236, 0.2),
     0 40px 0 0 rgba(0, 82, 236, 0.2),}
  100% {
    box-shadow: -30px 40px 0 0 rgba(0, 82, 236, 0.2),
    -15px 40px 0 0 rgba(0, 82, 236, 0.2),
    0 40px 0 0 rgba(0, 82, 236, 0.2),}
  }
