/*================ Banner Style Part ================*/
.faqBannerSec{
	position: relative;
	background-color: var(--white);
	padding: 40px 0 30px 0;
	// width: 70%;
	// margin: 0 auto;
	@media only screen and (max-width:767px){
		padding: 20px 0 30px 0;
	}
}
.faqBannerSec .dFlex{
	display: flex;
}
.marGap{
	margin-right: 23px;
	width: 30px;
}
.marGap img{
	width: 100%;
}
.faqBannerSec h4{
	font-size: 25px;
	color: var(--textColor);
	font-weight: 400;
	margin-bottom: 15px;
	margin-top: 30px;
	font-weight: 600;
	@media only screen and (max-width:767px){
		font-size: 20px;
		text-align: left;
	}
}
.faqBannerSec h4 img{
	margin-right: 15px;
	width: 33px;
}
.faqBannerSec p{
	font-size: 18px;
	color: var(--textColor);
	width: 92%;
	@media only screen and (max-width:767px){
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 13px;
		width: auto;
		text-align: left;
	}
}

.faqBannerSec ul{
	margin-left: 20px;
}
.faqBannerSec ul li{
	font-size: 18px;
	color: var(--textColor);
	width: 92%;	
	padding-bottom: 15px;
	list-style:disc;
}








.topMainHdr{
	position: relative;
	width: 100%;
	padding:17px 0;
	z-index: 9999;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	background: #006eda;
	@media only screen and (max-width:991px){
		padding: 10px 0;
	}
}
.hdrRow{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;	
}
.hdrLogo{
	position: relative;
	a{
	position: absolute;
	left: 0;
	top: -22px;
	}
}
.hdrLogo img{
	max-width: 100px;	
	@media only screen and (max-width:991px){
		max-width: 80px;
		margin-top: 5px;
	}
	@media only screen and (max-width:575px){
		max-width: 60px;
		margin-top: 5px;
	}
}





/*====== Menu Style Start ======*/
.hdrMenuPrt{
	z-index: 99;
}
.headerMenu{
	font-size: 18px;
	color: var(--primary);
	font-weight: 400;
	letter-spacing: 0.5px;
	@media only screen and (max-width: 991px){
		background-color: transparent;
		z-index: 99;
		font-size: 15px;
	}
}


.headerMenu li{ margin: 0 12px; position: relative; }
.headerMenu li:first-child{ margin-left: 0; }
.headerMenu li:last-child{ margin-right: 0; }
.headerMenu li a{ position: relative; color:#fff; padding: 6px 5px; text-decoration: none; font-size: 16px;}
.headerMenu li a:hover, .headerMenu .headerMenu li.active a{ color:#fff; text-decoration: underline;}

/*Hamburger Style*/
.hdrMenuPrt button{ 
	width: 27px; 
	height: 27px; 
	position: relative; 
	margin: 0px; 
	cursor: pointer; 
	outline: 0 !important; 
	border: none !important;
-webkit-transform: rotate(0deg);
-moz-transform: rotate(0deg);
-o-transform: rotate(0deg);
transform: rotate(0deg);
-webkit-transition: .5s ease-in-out;
-moz-transition: .5s ease-in-out;
-o-transition: .5s ease-in-out;
transition: .5s ease-in-out; 
z-index: 9999;
}
.hdrMenuPrt button span{ 
	position: absolute;
	right: 0;
	width: 22px;
	height: 2px;
	background-color: #0c2d86;
	border-radius: 30px;
	-webkit-transition: .2s ease-in-out;
	-moz-transition: .2s ease-in-out;
	-o-transition: .2s ease-in-out;
	transition: .2s ease-in-out; 
}
.hdrMenuPrt button span{ background: var(--white); }
.hdrMenuPrt button span:nth-child(1){ top: 0px; }
.hdrMenuPrt button span:nth-child(2), .hdrMenuPrt button span:nth-child(3){ top: 8px; width: 100%; }
.hdrMenuPrt button span:nth-child(4){ top: 16px; }
/*====== Menu Style End ======*/


/*=========== Menu Responsive Style Start ===========*/
@media only screen and (min-width: 992px) {
.topMainHdr .hdrMenuPrt button{
	display: none;
}
.headerMenu li a::before,
.headerMenu li a::after{
	position: absolute;
	content: '';
	width: 0;
	height: 2px;
	bottom: 0;
	background-color: var(--secondary);
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.headerMenu li a::before{
	left: 0;
}
.headerMenu li a::after{
	right: 0;
}
.headerMenu li a:hover::before,
.headerMenu li a:hover::after{
	width: 50%;
}

}


@media only screen and (max-width: 991px) {
	.headerMenu{
		background-color: transparent;
		z-index: 99;
		font-size: 15px;
	}
	
}
/*=========== Menu Responsive Style End ===========*/