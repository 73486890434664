:root {
    --primary: #010101;
    --secondary: #006eda;
    --white: #ffffff;
    --black: #000000;
    --yellow: #f1b730;
    --gray: #777c81;
    --lightGrayBg: #f7f7f7;
    --textColor: #3e3a3a;
    --footerTextColor: #8a98aa;
    --hdnFont: "Lato", sans-serif;
    --footerFont: "Lato", sans-serif;
    --text-heading-2: #51d045;
    --text-heading-3: #000000;
    --background-bg: #f3f3f3;
}
