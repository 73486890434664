.creditGames {
    background: var(--white);
    border-radius: 12px;
    padding: 15px;
    .gamesBox {
      background: #f1f1f1;
      padding: 20px 0;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .gamesImg {
        img {
          max-width: auto;
          width: 100px;
        }
      }
      h4 {
        margin: 20px 0 25px;
        font-size: 19px;
        line-height: 1.2;
        font-weight: 600;
      }
      .gameIcon {
        img {
          max-width: 60px;
        }
        cursor: pointer;
      }
    }
  }