.gamesWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--white);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  margin-bottom: 50px;
  padding: 15px 0 30px;
}
.gameWrapperBox {
  position: relative;
  .gameWrapperImg {
    text-align: center;
    img {
      display: inline-block;
      max-width: 400px;
      max-height: 300px;
      margin: 0 auto;
      border: 1px solid #ececec;
      border-radius: 15px;
      padding: 2px;
    }
  }

  .gamesContent {
    padding: 20px;
    max-width: 90%;
    margin: 0 auto;
    h4 {
      font-size: 24px;
      line-height: 1.4;
      font-weight: 500;
      text-align: center;
      margin: 0 10% 20px;
    }
    .timer{
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
    }
  }
  .answerSelect {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
    margin-right: -8px;
    li {
      position: relative;
      cursor: pointer;
      width: 50%;
      padding: 0 8px;
      margin-bottom: 15px;
      &.active {
        span {
          background: #32c423;
          color: #fff;
        }
        .answerPoint {
          background: #fff;
          color: #000000;
        }
      }
      &.incorrect {
        span {
          background: #d84233;
          color: #fff;
        }
        .answerPoint {
          background: #fff;
          color: #000000;
        }
      }
      &.selected {
        span {
          background: #006eda;
          color: #fff;
        }
        .answerPoint {
          background: #fff;
          color: #000000;
        }
      }
      span {
        display: block;
        background: #f1f1f1;
        border-radius: 8px;
        padding: 15px 25px;
        padding-left: 60px;
        font-size: 16px;
        line-height: 1.2;
        font-weight: 500;
        height: 100%;
      }
    }
  }
  .answerPoint {
    position: absolute;
    left: 13px;
    top: 5px;
    background: #006eda;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 8px;
  }
}

.gameFinished {
  max-width: 500px;
  margin: 30px auto;
  text-align: center;
  img {
    max-width: 180px;
  }
  p {
    line-height: 28px;
  }
}

.gamesBoxHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 15px 15px;
  border-bottom: 1px solid #ececec;
  margin-bottom: 20px;
  h4 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
  }
  .gamesBoxHeaderRight {
    font-size: 16px;
    line-height: 1.2;
    font-weight: 500;
    color: #7e7e7e;
    b {
      font-size: 17px;
      font-weight: 600;
      color: #212121;
    }
    span {
      font-size: 22px;
      font-weight: 600;
      color: #31c522;
    }
  }
}

.trueFalseAction {
  display: flex;
  align-items: center;
  justify-content: center;
   button + button {
    margin-left: 20px;
   }
  button {
    background: #32c423;
    border: none;
    border-radius: 25px;
    min-width: 120px;
    padding: 10px 0 12px;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
    box-shadow: 0 0 10px #32c423;
    &.false{
      background: #d84233;
      box-shadow: 0 0 10px #d84233;
    }
  }
}

.rightAnswerGif {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  img {
    width:100%;
    height: 100%;
  }
}

.answerBox {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  background: #f1f1f1;
  border-radius: 12px;
  box-shadow: 0 0 15px rgba(0,0,0,.25);
  width: 150px;
  height: 150px;  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all ease-in 0.5s;
  text-align: center;
  scale: 1;
  &.show {
    scale: 1;
  }
  img {
    width: 70px;
    margin-bottom: 10px;
  }
  .right {
    h5 {
      color: #32c423;
    }
  }
  h5 {
    font-size: 16px;
    font-weight: 600;
    color: #d84233;
  }
}


/* Credit Scrambler */
.creditScramblerWrapper {
  background: var(--white);
  border-radius: 12px;
  margin-bottom: 30px;
  padding-top: 15px;
}

.puzzleContainer {
  position: relative;
  .timer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }
}

.creditPuzzleBox {
  background: #0079f0;
  width: 360px;
  margin: 0 auto;
  border-radius: 8px;
  padding: 10px 10px 1px;
  margin-bottom: 40px;

  .puzzleRow {
    display: flex;
    margin-bottom: 10px;
    &.unAnswer {
      .puzzleWordBox {
        background: #000f1e;
      }
      .puzzleAnswerCheck {
        background: #000f1e;
      }
      .unjumbleText {
        display: block;
        text-align: center;
        font-size: 16px;
        line-height: 1.2;
        color: #d39c1e;
        margin-top: 7px;
      }      
    }
    &.wrongAnswer {
      .puzzleWordBox {
        background: #d84233;
      }
      .puzzleAnswerCheck {
        background: #d84233;
      }
    }
    &.rightAnswer {
      .puzzleWordBox {
        background: #32c423;
      }
      .puzzleAnswerCheck {
        background: #32c423;
      }
    }

    .puzzleWordBox {
      width: 280px;
      margin-right: 15px;
      background: #006eda;
      height: 46px;
      border-radius: 8px;
      padding: 6px;

      ul {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        li {
          width: 32px;
          height: 32px;
          background: #fff;
          border-radius: 8px;
          margin: 0 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          line-height: 32px;
          color: #333;
          font-weight: 600;
        }
      }
    }

    .puzzleAnswerCheck {
      background: #006eda;
      border-radius: 8px;
      width: 42px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 24px;
      }
    }
  }
}

.puzzleContainerBox {
  background: #cacaca;
  border-radius: 8px;
  padding: 10px;
  width: 360px;
  margin: 0 auto 30px;

  ul {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      width: 34px;
      height: 34px;
      background: #f1f1f1;
      border-radius: 8px;
      margin: 0 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      line-height: 34px;
      font-weight: 600;
    }
  }
}

.puzzleWord {
  margin-bottom: 50px;

  ul {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      width: 34px;
      height: 34px;
      background: #fff;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
      border-radius: 8px;
      margin: 0 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      line-height: 34px;
      font-weight: 600;

      &.wordBack {
        background: #d84233;
        cursor: pointer;
      }
    }
  }
}

.puzzleAction {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  button {
    background: #006eda;
    border: none;
    border-radius: 25px;
    min-width: 120px;
    padding: 10px 35px 12px;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
    box-shadow: 0 0 10px #006eda;
  }
}



