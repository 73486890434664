.profile_wrapper {
  padding-bottom: 80px;
  flex: 1;
  h3 {
    font-size: 22px;
    font-weight: 500;
  }
  h3 b {
    font-weight: 700;
  }
}
.informationHeader {
  display: flex;
  span {
    margin-left: 20px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1.2;
    img {
      width: 18px;
      margin-right: 5px;
    }
  }
}

.right_col_box {
  background: var(--white);
  border-radius: 12px;
  min-height: 300px;
  padding: 30px;
  h4 {
    font-size: 20px;
    font-weight: 600;
  }
}
.user_details {
  padding-top: 30px;
  .user_label_field {
    font-size: 14px;
    color: #8698b0;
    font-weight: 500;
    margin-bottom: 5px;
    display: block;
  }
  .user_form_group {
    position: relative;
    img {
      position: absolute;
      left: 0;
      top: 5px;
      width: 20px;
      fill: #8b9cb3;
    }
  }
  input,
  select {
    width: 100%;
    border: none;
    border-bottom: 1px solid #ebeef1;
    padding: 5px 0 5px 35px;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
  }
}
.infoIcon {
  color: red;
}
