/* index.css or App.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.owl-nav {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.owl-nav button {
  width: 40px;
  height: 40px;
  background: #fff!important;
  border-radius: 100%;
  margin: 0 10px;
}
.owl-nav span {
  font-size: 50px;
  line-height: 30px;
}
/* Modal UI */
.modal.show {
  display: flex;
  background: rgba(0, 0, 0, .5);
  align-items: center;
}
.information-content {
  position: relative;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 30px;
}
.information-content h4 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 15px;
}
.information-content p{
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 0 12%;
}
.information-img {
  width: 90px;
  height: 90px;
  margin: 0 auto 20px;
  background: #fff6cc;
  border-radius: 100%;
  border: 2px solid #f3e394;
  display: flex;
  align-items: center;
  justify-content: center;
}
.information-img img {
  width: 50px;
}
.modal-close-btn {
  position: absolute;
  right: 30px;
  top: 15px;
  background: transparent;
  border: none;
  padding: 0;
}
.modal-close-btn img {
  width: 30px;
}

.game-modal-img  img {
  max-width: 160px;
  margin-bottom: 30px;
}


