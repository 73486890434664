.personalCreditInfo {
  margin-top: -106px;
  margin-left: -30px;
}
.creditReport {
  background: var(--white);
  border-radius: 12px;
  min-height: 300px;
  padding: 30px;
}

