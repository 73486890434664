.inner_page_wrapper {
  background-color: var(--lightGrayBg);
  min-height: calc(100vh - 89px);
  padding: 100px 0 0;
}

.page_wrapper_row {
  display: flex;
  flex-wrap: wrap;
  &.chat_page_wrapper {
    height: calc(100vh - 189px);
    .page_col_right {
      overflow: hidden;
      height: 100%;
      padding-bottom: 20px;
    }
  }
}

.page_col_left {
  flex: 0 auto;
  width: 320px;
  margin-right: 30px;

  .sidebar_col_menu {
    background: var(--white);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    padding: 15px;
    li {
      margin-bottom: 10px;
      a {
        padding: 0 15px;
        height: 52px;
        font-size: 16px;
        color: var(--black);
        font-weight: 600;
        display: flex;
        align-items: center;
      }
      img {
        width: 32px;
        margin-right: 15px;
      }
    }
  }
}

.page_col_right {
  flex: 1;
  h3 {
    font-size: 22px;
    font-weight: 500;
  }
  h3 b {
    font-weight: 700;
  }
}

.right_col_box {
  background: var(--white);
  border-radius: 12px;
  min-height: 300px;
  padding: 30px;
  h4 {
    font-size: 20px;
    font-weight: 600;
  }
}
.user_details {
  padding-top: 30px;
  .user_label_field {
    font-size: 14px;
    color: #8698b0;
    font-weight: 500;
    margin-bottom: 5px;
    display: block;
  }
  .user_form_group {
    position: relative;
    img {
      position: absolute;
      left: 0;
      top: 5px;
      width: 20px;
      fill: #8b9cb3;
    }
  }
  input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #ebeef1;
    padding: 5px 0 5px 35px;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
  }
}

.active {
  background-color: #f1f1f1;
  border-radius: 8px;
}

.personalCreditInfo {
  margin-top: -106px;
  margin-left: -30px;
}
.creditReport {
  background: var(--white);
  border-radius: 12px;
  min-height: 300px;
  padding: 30px;
}








.creditCardWrapper {
  background: var(--white);
  border-radius: 12px;
  margin-bottom: 40px;
}

.creditCardxHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #ececec;
  margin-bottom: 20px;
  h4 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
  }
}

.creditCardContent {
  max-width: 500px;
  margin: 0 auto;
  padding: 15px 15px 50px;
  h4 {
    font-size: 26px;
    line-height: 1.2;
    font-weight: 600;
    margin-bottom: 30px;
  }
  h3 {
    font-size: 26px;
    line-height: 1.2;
    font-weight: 600;
    color: #000000;
  }
}
.creditCardImage {
  img {
    margin-bottom: 30px;
  }
}

.cardApprovedWrapper {
  display: flex;
  align-items: center;
  padding-top: 30px;
  img {
    max-width: 60px;
    margin-right: 20px;
  }
  h5 {
    font-size: 23px;
  }
}

.cardApply {
  text-align: center;
  padding: 50px 0 20px;
}
