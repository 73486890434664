.loginWrapper {
    background: var(--lightGrayBg);   
    height: calc(100vh);
}
.loginContainer {
    height: calc(100vh - 66px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.loginBox {
    width: 360px;
    padding: 0px 50px 0;
    margin: 0 auto;
    background-image: url(../../../assets/images/login_box.svg);
    background-repeat: no-repeat;
    background-size: 100%; 
    height: 420px;
    display: flex;
    align-items: center;
    flex-direction: column;    
}
.loginInfo {
    margin-top: 60px;
    width: 100%;
    button {
        width: 100%;
        background: var(--lightGrayBg);
        border-radius: 25px;
        border: 1px solid #eeeeee;
        text-align: center;
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 400;
        padding: 10px 0 8px;
        display: flex;
        justify-content: center;
        img {
            width: 22px;
            margin-right: 15px;
        }
    }
}